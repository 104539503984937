import React from 'react';
import { useSession } from 'next-auth/react';
import { SpinnerIcon } from '@dinbog/ui';
import { useRouter } from 'next/router';

function Auth({ children }) {
  const router = useRouter();
  const { status } = useSession({ required: true });

  // FIXME: OJO CON ESTE USEEFFECT
  /* React.useEffect(() => {
    if (status === 'authenticated') {
      router.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]); */

  if (status === 'loading') {
    return (
      <div className="w-full h-[70vh] flex  opacity-70 z-30">
        <SpinnerIcon className="m-auto w-24 h-24 text-gray-200 animate-spin  fill-primary-500" />
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  return children;
}

export default Auth;
