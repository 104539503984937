import React from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { User } from '@dinbog/models';
import { usePermission } from '../hooks/api/permissionGroups';

interface IUserContext {
  user: Partial<User> & { token: string };
  setUser: React.Dispatch<
    React.SetStateAction<Partial<User> & { token: string }>
  >;
}

export const UserContext = React.createContext<IUserContext>({
  user: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUser: () => {},
});

interface UserContextProviderProps {
  children: React.ReactNode;
}

export function UserContextProvider({ children }: UserContextProviderProps) {
  const router = useRouter();
  const session = useSession();
  const [user, setUser] = React.useState<User & { token: string }>(null);
  const { data, isFetching, isLoading } = usePermission({
    _id: (session.data?.user?.user?.permissionGroup as string) ?? null,
  });

  React.useEffect(() => {
    if (
      session.status === 'authenticated' &&
      session?.data?.user?.user?.permissionGroup
    ) {
      setUser({
        token: session.data.user.token,
        ...(session.data.user.user as User),
        permissionGroup: data ?? session?.data?.user?.user?.permissionGroup,
      });
    } else {
      router.push('/sign-in');
      setUser(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.data?.user, session?.status]);

  React.useEffect(() => {
    if (session.status === 'authenticated' && data) {
      const _user = {
        token: session.data.user.token,
        ...session.data.user.user,
        permissionGroup: data,
      };
      setUser(_user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const value = React.useMemo(() => ({ user, setUser }), [user]);
  if (session.status === 'loading' || isLoading) {
    return null;
  }
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
