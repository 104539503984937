import React from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { Lato } from 'next/font/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NextComponentType } from 'next';
import {
  ThemeContextProvider,
  ToastContextProvider,
  UserContextProvider,
} from '../context';
import '../style.css';
import Auth from '../components/auth/Auth';

const queryClient = new QueryClient();

const lato = Lato({
  subsets: ['latin'],
  variable: '--font-lato',
  weight: ['100', '300', '400', '700', '900'],
});

type CustomAppProps = AppProps & {
  Component: NextComponentType & { auth?: boolean }; // add auth type to protect routes
};

// @ts-expect-error err is not defined
function MyApp({ Component, pageProps, err }: CustomAppProps<any>) {
  React.useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      NProgress.start();
    });

    Router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });

    Router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, []);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      // On page load or when changing themes, best to add inline in `head` to avoid FOUC
      if (
        localStorage.getItem('theme') === 'dark' ||
        (!('theme' in localStorage) &&
          window.matchMedia('(prefers-color-scheme: dark)').matches)
      ) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider session={pageProps.session} refetchInterval={0}>
        <>
          <Head>
            <title>Dashboard | Dinbog</title>
          </Head>
          <ThemeContextProvider>
            <ToastContextProvider>
              {Component?.auth ? (
                <Auth>
                  <UserContextProvider>
                    <main className={`${lato.variable} font-text`}>
                      <Component {...pageProps} err={err} />
                    </main>
                  </UserContextProvider>
                </Auth>
              ) : (
                <Component {...pageProps} />
              )}
            </ToastContextProvider>
          </ThemeContextProvider>
        </>
      </SessionProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
