import { PermissionGroup } from '@dinbog/models';
import { AxiosError } from 'axios';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { axios } from '../../api';

export function useCreatePermissionGroup(
  options: Omit<
    UseMutationOptions<
      PermissionGroup,
      AxiosError,
      Partial<PermissionGroup>,
      unknown
    >,
    'mutationFn'
  > = {}
) {
  const mutation = useMutation<
    PermissionGroup,
    AxiosError,
    Partial<PermissionGroup>
  >(async (newPermissionGroup) => {
    const { data } = await axios.post(
      '/api/users/permissionGroups/v1',
      newPermissionGroup
    );
    return data;
  }, options);
  return mutation;
}

export function useUpdatePermissionGroup(
  options: Omit<
    UseMutationOptions<
      PermissionGroup,
      AxiosError,
      Partial<PermissionGroup>,
      unknown
    >,
    'mutationFn'
  > = {}
) {
  const mutation = useMutation<
    PermissionGroup,
    AxiosError,
    Partial<PermissionGroup>
  >(async (updatedPermission) => {
    const { data } = await axios.patch(
      '/api/users/permissionGroups/v1',
      updatedPermission
    );
    return data;
  }, options);
  return mutation;
}

export function usePermission(
  filters: Partial<PermissionGroup>,
  options: Omit<
    UseQueryOptions<
      PermissionGroup,
      AxiosError,
      PermissionGroup,
      ['permission']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<PermissionGroup, AxiosError>(
    ['permission', filters?._id],
    async () => {
      const { data } = await axios.get<PermissionGroup>(
        `/api/users/permissionGroup/v1`,
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function usePermissions(
  filters?: Partial<PermissionGroup>,
  options: Omit<
    UseQueryOptions<
      PermissionGroup[],
      AxiosError,
      PermissionGroup[],
      ['permissions']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<PermissionGroup[], AxiosError>(
    ['permissions'],
    async () => {
      const { data } = await axios.get<PermissionGroup[]>(
        '/api/users/permissionGroups/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}
